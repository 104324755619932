<!--
About Page
Created at 09/04/2021 11:45
Author: Khaliq ALI
!-->
<template>
<div class="h-screen w-full md:flex items-center justify-center text-3xl font-bold">
  <div class="h-12 p-2 text-xl font-normal flex items-center border rounded shadow cursor-pointer text-black dddd">bbbbb</div>
</div>
</template>

<script>
export default {
  name: 'index'
}
</script>

<style lang="scss" scoped>
.dddd {
  background-color: red;
}
</style>
